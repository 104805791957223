// title: Cube Draft Formats
// frameCardName: Information Dealer
// frameCardSet: ONS

import * as styles from './index.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

import FormatsList from 'components/formats/FormatsList'

interface Props {
  pageContext: any
}

const FormatsPage: React.FC<Props> = (props) => {
  return (
    <Layout
      {...props.pageContext}
      accentColor="#3980B2"
      includeNewsletterSignup
    >
      <div>
        <div className={styles.intro}>
          <h1 className={styles.heading}>Cube Draft Formats</h1>

          <p>
            There&rsquo;s more than one way to draft a Magic the Gathering:
            Cube. Whether you&rsquo;re looking for a way to draft with a
            specific number of players, or just a new way to play, these options
            have you covered.
          </p>

          <p>
            All of these formats are flexible to at least some degree. A
            baseline, and common variations, for each are described, but the
            numbers, or even broad strokes, are all flexible to suite your cube
            and your playgroup. This list is lightly curated and somewhat
            opinionated. If we&rsquo;re missing something, please{' '}
            <Link to="/about/">let us know</Link>!
          </p>
        </div>

        <FormatsList />

        <Typography.Divider />
      </div>
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'MTG Cube Draft Formats - Lucky Paper',
        description: 'Every way to draft your Magic the Gathering Cube',
      }}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: 'MTG Cube Draft Formats - Lucky Paper',
        url: props.pageContext.url,
      }}
    />
  )
}

export default FormatsPage
