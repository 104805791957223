import * as styles from './FormatsList.module.scss'

import React, { useMemo } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { orderBy } from 'lodash'

import Logo from '../Logo'

interface Props {}

const FormatsList: React.FC<Props> = () => {
  const {
    allMdx: { formats },
  } = useStaticQuery<Queries.FormatsListQuery>(
    graphql`
      query FormatsList {
        allMdx(
          filter: {
            frontmatter: { published: { eq: true } }
            fileAbsolutePath: { regex: "/format-guide/" }
          }
        ) {
          formats: nodes {
            id
            frontmatter {
              title
              slug
              description
              players
              editorsChoice
            }
          }
        }
      }
    `,
  )

  const sortedFormats = useMemo(
    () =>
      orderBy(
        formats,
        ['frontmatter.editorsChoice', 'frontmatter.title'],
        ['desc', 'asc'],
      ),
    [formats],
  )

  return (
    <div className={styles.container}>
      {sortedFormats.map((format) => {
        if (format.frontmatter == null) {
          return null
        }

        return (
          <Link
            key={format.id}
            to={`/resources/formats/${format.frontmatter.slug}/`}
          >
            <div className={styles.title}>{format.frontmatter.title}</div>

            <div className={styles.description}>
              {format.frontmatter.description}
            </div>

            {format.frontmatter.players && (
              <div className={styles.players}>{format.frontmatter.players}</div>
            )}

            {format.frontmatter.editorsChoice && (
              <div className={styles.editorsChoice}>
                <Logo size="9px" color="#ffffff" /> Editors&rsquo; Choice
              </div>
            )}
          </Link>
        )
      })}
    </div>
  )
}

export default FormatsList
